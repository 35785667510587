@import 'styles/breakpoints';

.root {
	background-color: var(--bg-layer);
	padding: var(--spacing-600) 0;

	@include from($widthTablet) {
		padding: var(--spacing-800) 0;
	}
}

.layout {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--spacing-600);
}

.title {
	font-size: var(--heading-xs-font-size);
	line-height: var(--heading-xs-line-height);
	font-weight: 400;
}

.list {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--spacing-600);
	max-width: 200px;

	@include from($widthTablet) {
		flex-direction: row;
		gap: var(--spacing-600);
		flex-wrap: nowrap;
		max-width: 100%;
	}
}

.list-item {
	flex: 0 1 auto;
}

.link {
	filter: grayscale(1);

	&:hover, &:focus {
		filter: grayscale(0);
	}
	svg {
		display: block;
		width: 100%;
		height: 100%;
		max-height: 150px;
	}
}
