@import 'styles/breakpoints';

.root {
  border-bottom: 1px solid var(--border-subdued);
  background-color: var(--white);

  @media screen and (min-width: $widthDesktop) {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list {
  display: flex;
  gap: var(--spacing-600)
}

.page-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  color: var(--text-default);
  text-decoration: none;

  &:hover, &:focus-visible,
  &.active-page-link {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: var(--spacing-100);
      background-color: var(--accent-brand);
    }
  }

  &:hover, &:focus-visible {
    opacity: .8;
  }
}
