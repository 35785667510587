@import 'styles/breakpoints';

.root {
  position: sticky;
  top: 0;
  z-index: 1;
}

.inner {
  min-height: 100vh; // fallback for dvh
  min-height: 100dvh;
  background: linear-gradient(rgba(var(--blue-900), .8), transparent);
  backdrop-filter: blur(1px)
}

.nav {
  background-color: var(--white);
}

.open {
  max-height: 100vh; // fallback for dvh
  max-height: 100dvh;
  width: 100vw;
  position: fixed;
  top: 0;
  overflow: scroll;
  z-index: 999;
}

.toggler {
  display: flex;
  align-items: center;
  gap: var(--spacing-300);
  width: 100%;
  padding: var(--spacing-400) clamp(var(--spacing-400), 5vw, var(--spacing-900));
  border-bottom: 1px solid var(--border-subdued);
  cursor: pointer;
  background-color: var(--white);
  z-index: 1;
  font-size: var(--text-md-font-size);
  line-height: var(--text-md-line-height);
  font-weight: 600;
  color: var(--text-default);

  .root.open & {
    position: sticky;
    top: 0;
  }
}

.page-link {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-400) clamp(var(--spacing-400), 5vw, var(--spacing-900));
  border-bottom: 1px solid var(--border-subdued);
  text-decoration: none;

  .nav li:last-child & {
    border-bottom: none;
  }

  font-size: var(--text-md-font-size);
  line-height: var(--text-md-line-height);
  font-weight: 400;
  color: var(--text-default);

  &.active-page-link {
    font-weight: 600;
  }
}

.contentLinks {
  padding: var(--spacing-400) clamp(var(--spacing-400), 5vw, var(--spacing-900));
  border-bottom: 1px solid var(--border-subdued);
}
