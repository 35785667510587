@import 'styles/breakpoints';

$aside-width-left: 196px;
$aside-width-right: 314px;

.container {
	position: relative;
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
	padding-left: clamp(var(--spacing-400), 5vw, var(--spacing-900));
	padding-right: clamp(var(--spacing-400), 5vw, var(--spacing-900));

	// Grid logic
	// has 'left' OR 'right' aside (= .left, .right)
	// switches to grid layout from TABLET
	&:has(.left, .right) {
		@include from($widthTablet) {
			display: grid;
			gap: var(--spacing-600);
			align-items: stretch;
		}
	}

	// has 'left' AND 'right' aside (= .left ~ .right)
	// switches to grid layout from DESKTOP
	&:has(.left ~ .right) {
		display: block;

		@media screen and (min-width: $widthDesktop) {
			display: grid;
			gap: var(--spacing-600);
			align-items: stretch;
		}
	}

	// Area & columns logic
	&:has(.right) {
		grid-template-areas: 'main right';
		grid-template-columns: 1fr $aside-width-right;
	}

	&:has(.left) {
		grid-template-areas: 'left main';
		grid-template-columns: $aside-width-left 1fr;
	}

	&:has(.left ~ .right) {
		grid-template-areas: 'left main right';
		grid-template-columns: $aside-width-left 1fr $aside-width-right;
	}
}

.left {
	grid-area: left;
}
.main {
	grid-area: main;

	&[id] {
		scroll-margin-top: var(--spacing-800);

		@media screen and (min-width: $widthDesktop){
			scroll-margin-top: var(--spacing-900);
		}
	}
}
.right {
	grid-area: right;
}

.left, .right {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-600);
}

.sticky {
	position: sticky;
	top: var(--js-sticky-top, 0px);
}
