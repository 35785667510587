@import 'styles/breakpoints';

.hero {
  .hero-image {
    object-position: center 75%;

    @include from($widthTablet) {
      object-position: center bottom;
    }
  }
}

.outlet {
  padding: var(--spacing-400) 0;

  @media screen and (min-width: $widthDesktop) {
    padding: var(--spacing-900) 0;
  }
}
