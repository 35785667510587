@import 'styles/breakpoints';

.root {
  background-color: rgba(0, 0, 0, 0.03);
  padding: var(--spacing-400);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-400);
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-400);

  @include from($widthTablet) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }
}

.list-item {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-400);
  width: 100%;

  @include from($widthTablet) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: var(--spacing-200);
    max-width: 210px;
  }

  // redefined to overwrite loading skeleton default styles
  &.skeleton {
    gap: var(--spacing-400);

    @include from($widthTablet) {
      gap: var(--spacing-200);
    }
  }
}

.text {
  line-height: 1.6;
}

.hide-on-mobile {
  display: none;

  @include from($widthTablet) {
    display: initial;
  }
}

.progress span {
  color: var(--text-default);
  font-weight: 700;
}
