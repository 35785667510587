@import 'styles/breakpoints';

@keyframes loader {
  to {
    transform: translate3d(100%, 0, 0);
  }
}

.skeleton-group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-400);
  width: 100%;
}

.loader {
  background-color: hsl(0, 0%, 93%);
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: var(--radius-sm);
  max-width: 100%;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(-100%, 0, 0);
    animation: loader .8s infinite linear;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0) 81%);
  }

  .skeleton-group[data-variant='inverse'] & {
    background-color: rgba(var(--blue-800), 0.1);

      &:after {
        background: linear-gradient(to right, rgba(var(--blue-800), 0) 0%, rgba(var(--blue-800), 0.1) 30%, rgba(var(--blue-800), 0) 81%);
      }
    }
}
