@import 'styles/breakpoints';

.root {
  margin-bottom: var(--spacing-600);
  position: relative;
  font-size: var(--text-sm-font-size);
  line-height: var(--text-sm-line-height);

  &[id] {
    scroll-margin-top: var(--spacing-400);

    @include from($widthDesktop) {
      scroll-margin-top: var(--spacing-900);
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 var(--spacing-200);
  }

  h1 {
    font-size: var(--heading-lg-font-size);
    line-height: var(--heading-lg-line-height);
  }

  h2 {
    font-size: var(--heading-sm-font-size);
    line-height: var(--heading-sm-line-height);
  }

  h3 {
    font-size: var(--heading-xs-font-size);
    line-height: var(--heading-xs-line-height);
  }

  p {
    margin: 0 0 var(--spacing-600);
  }

  a {
    text-decoration: underline;
    color: var(--text-primary);
  }

  &.intro p {
    font-size: var(--text-md-font-size);
    line-height: var(--text-md-line-height);
  }

  blockquote {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-500);
    padding: var(--spacing-500);
    margin: 0 25px var(--spacing-600) 0;
    background-color: var(--accent-lightblue);
    border-radius: var(--radius-md);

    @include from($widthTablet) {
      padding: var(--spacing-600);
      margin-bottom: var(--spacing-800);
      margin-right: 0;
    }

    p {
      font-size: var(--text-lg-font-size);
      line-height: var(--text-lg-line-height);
    }

    footer {
      display: flex;
      align-items: center;
      gap: var(--spacing-400);

      img {
        display: block;
        max-width: 48px;
        max-height: 48px;
        margin: 0;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -52px;
      right: -26px;
      background-image: url("https://res.cloudinary.com/bolt-energie/image/upload/f_webp/c_scale,w_900/v1/website/energy-gardens/vy33cfsam1iwgg3usslj?_a=DAJFJtWIZAAB");
      background-size: 64px 97px;
      width: 64px;
      height: 97px;
      z-index: -1;
    }
  }

  ul:not([class]), ol:not([class]) {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-200);
    list-style-type: square;
    margin-left: var(--spacing-500);

    li::marker {
      color: var(--text-primary);
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 0 var(--spacing-600);

    @include from($widthTablet) {
      margin-bottom: var(--spacing-600);
    }
  }

  *:not(blockquote):last-child {
    margin-bottom: 0;
  }
}

.emphasized {
  background: var(--bg-layer);
  padding: var(--spacing-500);

  @include from($widthTablet) {
    padding: var(--spacing-600);
  }
}

