@import 'styles/breakpoints';

.list {
	display: flex;
	flex-direction: column;
	gap: var(--spacing-200);

	// sets first link active by default
	&:not(:has(.active-link)):not(:has([data-activelink="true"])) li:first-child .link {
		@extend .active-link
	}
}

.is-sticky {
	position: sticky;
	top: 0;

	@media screen and (min-width: $widthDesktop) {
		top: var(--spacing-900);
	}
}

.link {
	display: block;
	padding: var(--spacing-100) var(--spacing-400);
	font-weight: 400;
	min-height: 32px;
	color: var(--text-default);
	text-decoration: none;
	cursor: pointer;

	&:hover, &:focus-visible {
		opacity: .8;
	}
}

.link:hover, .link:focus,
.link[data-activelink="true"],
.active-link:not([data-activelink="false"]) {
	position: relative;
	font-weight: 700;
	color: var(--text-default);
	text-decoration: none;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: var(--spacing-100);
		background-color: var(--accent-brand);
	}
}
