@import 'styles/breakpoints';

// Background colors
.brand {
  background-color: var(--accent-brand);
}

.subdued {
  background-color: var(--bg-layer);
}

.transparent {
  background-color: transparent;
}

.pink {
  background-color: var(--accent-pink);
}

.yellow {
  background-color: var(--accent-yellow);
}

.lightblue {
  background-color: var(--accent-lightblue);
}

.orange {
  background-color: var(--accent-orange);
}

section.split-hero {
  height: auto;
  min-height: unset;

  @include from($widthTablet) {
    height: fit-content;
    min-height: 62vh;
  }

  &.with-form {
    overflow: unset;
    padding-bottom: calc(#{var(--spacing-900)} * 1.2);
    margin-bottom: calc(#{var(--spacing-900)} * 2.2);

    @include from($widthTablet) {
      min-height: 65vh;
      margin-bottom: var(--spacing-800);

      @media (height >= 800px) {
        padding-bottom: 40px;
        padding-top: 40px;
      }
    }
  }

  .right {
    max-height: 50vw;

    @include from($widthTablet) {
      max-height: unset;
    }
  }

  .form-container {
    position: absolute;
    display: flex;
    width: 100%;
    height: auto;
    max-width: 1400px;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(66%);

    @include from($widthTablet) {
      transform: translateY(33%);
    }

    .simulation-form {
      position: relative;
      transform: unset;
      width: 100%;
      margin: 0;
      z-index: 10;
    }
  }

  .hero-content {
    p {
      font-size: var(--text-md-font-size);
      line-height: var(--text-md-line-height);
    }
  }

  > svg {
    position: absolute;
    margin: 0;
    padding: 0;
    bottom: 0;
    left: 35%;
    width: auto;
    height: 28vh;

    @include until($widthMobile) {
      display: none;
    }
  }
}

.hero-cta-group {
  display: flex;
  gap: var(--spacing-400);
  flex-wrap: wrap;

  @include until($widthMobile) {
    justify-content: center;
  }

  @include from($widthDesktop) {
    gap: var(--spacing-500);
  }

  > * {
    // all first level children
    display: flex;
    gap: var(--spacing-200);
    align-items: center;
    justify-content: center;
  }

  button,
  a {
    width: fit-content;

    @include until($widthMobile) {
      width: 100%;
    }
  }

  p {
    line-height: 1.6;
  }

  svg {
    vertical-align: middle;
    height: 16px;
  }
}

.figure {
  width: 100%;
  height: 100%;
  position: relative;

  .hero-video {
    width: 100%;
    height: 100%;

    > div {
      width: 100%;
      height: 100%;

      > div {
        padding-top: unset !important;
        height: 100% !important;
      }
    }
  }

  figcaption {
    position: absolute;
    padding: var(--spacing-500) var(--spacing-600);
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    color: var(--white);
    align-items: flex-end;
    width: 100%;
    background: radial-gradient(100% 100% at 100% 100%, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);

    strong,
    small {
      font-size: var(--text-xs-font-size);
      line-height: var(--text-xs-line-height);
    }
  }
}

section.hero {
  color: var(--text-default);
  padding-top: var(--spacing-600);
  padding-bottom: var(--spacing-600);
  clip-path: inset(0 -100vmax);

  @include from($widthDesktop) {
    padding-top: var(--spacing-900);
    padding-bottom: var(--spacing-900);
  }

  &.brand {
    box-shadow: 0 0 0 100vmax var(--accent-brand);
  }

  &.subdued {
    box-shadow: 0 0 0 100vmax var(--bg-layer);
  }

  &.transparent {
    box-shadow: 0 0 0 100vmax transparent;
  }

  &.pink {
    box-shadow: 0 0 0 100vmax var(--accent-pink);
  }

  &.yellow {
    box-shadow: 0 0 0 100vmax var(--accent-yellow);
  }

  .title {
    max-width: 600px;
    margin-bottom: var(--spacing-400);
  }

  p {
    max-width: 773px;
    font-size: var(--text-md-font-size);
    line-height: var(--text-md-line-height);
  }

  .hero-cta-group {
    margin-top: var(--spacing-600);

    a:not([class]) {
      color: var(--text-default);
      text-decoration: underline;
      font-weight: bold;
      padding: var(--spacing-400) var(--spacing-500);
    }
  }
}
