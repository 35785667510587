@import 'styles/breakpoints';

.energy-gardens-header {
  display: flex;
  align-items: center;
  position: relative;
  max-width: unset;
  padding-top: var(--spacing-400);
  padding-bottom: var(--spacing-400);

  &.fixed {
    position: fixed;
    z-index: 11;
  }

  @include from($widthDesktop) {
    padding-top: var(--spacing-600);
    padding-bottom: var(--spacing-600);
  }

  :global(.container) {
    @include from($header-break-point) {
      max-width: 2160px;
      padding-left: clamp(var(--spacing-400), 5vw, 60px);
      padding-right: clamp(var(--spacing-400), 5vw, 60px);
    }

    @include from($widthDesktopLarge) {
      max-width: 2280px;
      padding-left: clamp(var(--spacing-400), 10vw, 120px);
      padding-right: clamp(var(--spacing-400), 10vw, 120px);
    }
  }

  nav.menu {
    height: auto;
    width: 100%;

    .badge {
      flex-shrink: 0;
      gap: var(--spacing-100);
      padding: var(--spacing-100);
      border-radius: 3px;
      background-color: var(--accent-yellow-subdued);
      color: var(--on-yellow-subdued);
      font-weight: bold;
    }
  }
}
